export const zipArray = [
    0, 19999, 21999, 22999, 23999, 24999, 25999, 26999, 27999, 28999, 29999, 30999, 31999, 33999, 34999, 35999, 36999,
    37999, 38999, 39999, 40999, 41999, 42999, 43999, 44999, 45999, 46999, 47999, 50999, 51999, 52999, 53999, 54999,
    55999, 56999, 57999, 58999, 59999, 60999, 61999, 63999, 64999, 65999, 66999, 67999, 68999, 69999, 70999, 71999,
    72999, 73999, 74999, 75999, 76999, 77999, 78999, 79999, 80999, 81999, 82999, 83999, 84999, 85999, 86999, 87999,
    88999, 89999, 90999, 91999, 92999, 93999, 94999, 95999, 96999, 97999, 98999,
];

export const kgArray = [101, 500, 1000, 2500, 5000, 10000, 15000, 20000, 25000];

export const priceArray = [
    [276.3, 175.8, 137, 97.1, 68.5, 51.4, 51.4, 50.2, 49.1],
    [92.5, 52.5, 48, 29.7, 18.3, 12.6, 12.6, 12.6, 12.6],
    [92.5, 52.5, 48, 29.7, 18.3, 12.6, 12.6, 12.6, 12.6],
    [92.5, 52.5, 48, 29.7, 18.3, 12.6, 12.6, 12.6, 12.6],
    [116.5, 73.1, 61.7, 35.4, 21.7, 14.8, 14.8, 14.8, 14.8],
    [131.3, 81.1, 70.8, 37.7, 22.8, 16, 16, 16, 16],
    [131.3, 81.1, 70.8, 37.7, 22.8, 16, 16, 16, 16],
    [135.9, 89.1, 71.9, 38.8, 24, 17.1, 17.1, 17.1, 17.1],
    [150.7, 90.2, 75.4, 40, 27.4, 18.3, 18.3, 18.3, 17.1],
    [150.7, 90.2, 75.4, 40, 27.4, 18.3, 18.3, 18.3, 17.1],
    [159.9, 97.1, 78.8, 41.1, 27.4, 18.3, 18.3, 18.3, 17.1],
    [182.7, 102.8, 86.8, 49.1, 28.5, 22.8, 22.8, 22.8, 21.7],
    [204.4, 115.3, 97.1, 53.7, 30.8, 24, 24, 24, 22.8],
    [189.5, 107.3, 89.1, 51.4, 28.5, 22.8, 22.8, 22.8, 21.7],
    [189.5, 107.3, 89.1, 51.4, 28.5, 22.8, 22.8, 22.8, 21.7],
    // [189.5, 107.3, 89.1, 51.4, 28.5, 22.8, 22.8, 22.8, 21.7],
    [205.5, 117.6, 98.2, 58.2, 33.1, 26.3, 26.3, 26.3, 24],
    [191.8, 110.8, 90.2, 52.5, 29.7, 24, 24, 24, 22.8],
    [226.1, 130.2, 102.8, 66.2, 41.1, 32, 32, 32, 29.7],
    [224.9, 126.7, 101.6, 55.9, 38.8, 29.7, 29.7, 29.7, 28.5],
    [224.9, 126.7, 101.6, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [224.9, 126.7, 101.6, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [224.9, 126.7, 101.6, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [205.5, 117.6, 98.2, 58.2, 33.1, 26.3, 26.3, 26.3, 24],
    [224.9, 126.7, 102.8, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [234.1, 137, 110.8, 68.5, 46.8, 34.3, 34.3, 34.3, 33.1],
    [234.1, 137, 110.8, 68.5, 46.8, 34.3, 34.3, 34.3, 33.1],
    [226.1, 130.2, 102.8, 66.2, 41.1, 32, 32, 32, 29.7],
    [224.9, 126.7, 101.6, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [224.9, 126.7, 101.6, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [224.9, 126.7, 102.8, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [239.8, 139.3, 115.3, 71.9, 48, 34.3, 34.3, 34.3, 33.1],
    [239.8, 139.3, 115.3, 71.9, 48, 34.3, 34.3, 34.3, 33.1],
    [212.4, 123.3, 100.5, 61.7, 37.7, 27.4, 27.4, 27.4, 24],
    [212.4, 123.3, 100.5, 61.7, 37.7, 27.4, 27.4, 27.4, 24],
    [224.9, 126.7, 101.6, 62.8, 38.8, 29.7, 29.7, 29.7, 28.5],
    [240.9, 141.6, 116.5, 73.1, 49.1, 37.7, 37.7, 37.7, 34.3],
    [240.9, 141.6, 116.5, 73.1, 49.1, 37.7, 37.7, 37.7, 34.3],
    [244.3, 149.6, 118.7, 76.5, 52.5, 38.8, 38.8, 38.8, 37.7],
    [252.3, 156.4, 125.6, 83.4, 55.9, 41.1, 41.1, 41.1, 40],
    [270.6, 167.8, 134.7, 90.2, 62.8, 50.2, 50.2, 49.1, 46.8],
    [261.5, 161, 127.9, 85.6, 61.7, 44.5, 44.5, 44.5, 41.1],
    [261.5, 161, 127.9, 85.6, 61.7, 44.5, 44.5, 44.5, 41.1],
    [252.3, 156.4, 125.6, 83.4, 55.9, 41.1, 41.1, 41.1, 40],
    [270.6, 167.8, 134.7, 90.2, 62.8, 50.2, 50.2, 49.1, 46.8],
    [270.6, 167.8, 134.7, 90.2, 62.8, 50.2, 50.2, 49.1, 46.8],
    [244.3, 149.6, 118.7, 76.5, 52.5, 38.8, 38.8, 38.8, 37.7],
    [252.3, 156.4, 125.6, 83.4, 55.9, 41.1, 41.1, 41.1, 40],
    [261.5, 161, 127.9, 85.6, 61.7, 44.5, 44.5, 44.5, 41.1],
    [270.6, 167.8, 134.7, 90.2, 62.8, 50.2, 50.2, 49.1, 46.8],
    [276.3, 175.8, 137, 97.1, 68.5, 51.4, 51.4, 50.2, 49.1],
    [319.7, 197.5, 156.4, 110.8, 79.9, 52.5, 52.5, 52.5, 50.2],
    [307.1, 195.2, 151.9, 107.3, 76.5, 51.4, 51.4, 50.2, 49.1],
    [319.7, 197.5, 156.4, 110.8, 79.9, 52.5, 52.5, 52.5, 50.2],
    [307.1, 195.2, 151.9, 107.3, 76.5, 51.4, 51.4, 50.2, 49.1],
    [307.1, 195.2, 151.9, 107.3, 76.5, 51.4, 51.4, 50.2, 49.1],
    [319.7, 197.5, 156.4, 110.8, 79.9, 52.5, 52.5, 52.5, 50.2],
    [324.3, 203.2, 166.7, 117.6, 83.4, 57.1, 57.1, 55.9, 54.8],
    [324.3, 203.2, 166.7, 117.6, 83.4, 57.1, 57.1, 55.9, 54.8],
    [408.8, 262.6, 206.7, 155.3, 110.8, 73.1, 73.1, 73.1, 70.8],
    [439.6, 292.3, 247.8, 195.2, 138.2, 93.6, 91.3, 93.6, 89.1],
    [427, 279.7, 229.1, 173.6, 125.7, 83.4, 83.4, 83.4, 78.8],
    [415.6, 270.6, 223.8, 167.8, 118.7, 79.9, 79.9, 79.9, 74.2],
    [427, 279.7, 228.4, 173.6, 125.6, 83.4, 83.4, 83.4, 78.8],
    [427, 279.7, 228.4, 173.6, 125.6, 83.4, 83.4, 83.4, 78.8],
    [431.6, 280.9, 229.5, 183.8, 130.2, 84.5, 84.5, 84.5, 84.5],
    [431.6, 280.9, 229.5, 183.8, 130.2, 84.5, 84.5, 84.5, 84.5],
    [439.6, 292.3, 247.8, 195.2, 138.2, 93.6, 93.6, 93.6, 89.1],
    [455.6, 307.1, 262.6, 213.5, 149.6, 99.3, 99.3, 99.3, 97.1],
    [455.6, 307.1, 262.6, 213.5, 149.6, 99.3, 99.3, 99.3, 97.1],
    [477.3, 324.3, 282, 231.8, 164.4, 102.8, 102.8, 102.8, 102.8],
    [477.3, 324.3, 282, 231.8, 164.4, 102.8, 102.8, 102.8, 102.8],
    [492.1, 340.3, 298, 242.1, 174.7, 111.9, 111.9, 111.9, 108.5],
    [503.5, 351.7, 309.4, 252.3, 186.1, 116.5, 116.5, 116.5, 116.5],
    [483, 330, 292.3, 240.9, 169, 108.5, 108.5, 108.5, 106.2],
    [522.9, 369.9, 325.4, 267.2, 206.7, 127.9, 127.9, 127.9, 125.6],
];

// 2022-09-07 upgrade oldPriArray * (1+3.8%*)
const oldPriArray = [
    [266.2, 169.4, 132.0, 93.5, 66.0, 49.5, 49.5, 48.4, 47.3], //10000-19999
    [89.1, 50.6, 46.2, 28.6, 17.6, 12.1, 12.1, 12.1, 12.1], //20000-21999
    [89.1, 50.6, 46.2, 28.6, 17.6, 12.1, 12.1, 12.1, 12.1], //22000-22999
    [89.1, 50.6, 46.2, 28.6, 17.6, 12.1, 12.1, 12.1, 12.1], //23000-23999
    [112.2, 70.4, 59.4, 34.1, 20.9, 14.3, 14.3, 14.3, 14.3], //24000-24999
    [126.5, 78.1, 68.2, 36.3, 22.0, 15.4, 15.4, 15.4, 15.4], //25000-25999
    [126.5, 78.1, 68.2, 36.3, 22.0, 15.4, 15.4, 15.4, 15.4], //26000-26999
    [130.9, 85.8, 69.3, 37.4, 23.1, 16.5, 16.5, 16.5, 16.5], //27000-27999
    [145.2, 86.9, 72.6, 38.5, 26.4, 17.6, 17.6, 17.6, 16.5], //28000-28999
    [145.2, 86.9, 72.6, 38.5, 26.4, 17.6, 17.6, 17.6, 16.5], //29000-29999
    [154.0, 93.5, 75.9, 39.6, 26.4, 17.6, 17.6, 17.6, 16.5], //30000-30999
    [176.0, 99.0, 83.6, 47.3, 27.5, 22.0, 22.0, 22.0, 20.9], //31000-31999
    [196.9, 111.1, 93.5, 51.7, 29.7, 23.1, 23.1, 23.1, 22.0], //33000-33999
    [182.6, 103.4, 85.8, 49.5, 27.5, 22.0, 22.0, 22.0, 20.9], //34000-34999
    [182.6, 103.4, 85.8, 49.5, 27.5, 22.0, 22.0, 22.0, 20.9], //35000-35999
    // [182.6, 103.4, 85.8, 49.5, 27.5, 22.0, 22.0, 22.0, 20.9],
    [198.0, 113.3, 94.6, 56.1, 31.9, 25.3, 25.3, 25.3, 23.1], //36000-36999
    [184.8, 106.7, 86.9, 50.6, 28.6, 23.1, 23.1, 23.1, 22.0], //37000-37999
    [217.8, 125.4, 99.0, 63.8, 39.6, 30.8, 30.8, 30.8, 28.6], //38000-38999
    [216.7, 122.1, 97.9, 53.9, 37.4, 28.6, 28.6, 28.6, 27.5], //39000-39999
    [216.7, 122.1, 97.9, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //40000-40999
    [216.7, 122.1, 97.9, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //41000-41999
    [216.7, 122.1, 97.9, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //42000-42999
    [198.0, 113.3, 94.6, 56.1, 31.9, 25.3, 25.3, 25.3, 23.1], //43000-43999
    [216.7, 122.1, 99.0, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //44000-44999
    [225.5, 132.0, 106.7, 66.0, 45.1, 33.0, 33.0, 33.0, 31.9], //45000-45999
    [225.5, 132.0, 106.7, 66.0, 45.1, 33.0, 33.0, 33.0, 31.9], //46000-46999
    [217.8, 125.4, 99.0, 63.8, 39.6, 30.8, 30.8, 30.8, 28.6], //47000-47999
    [216.7, 122.1, 97.9, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //50000-50999
    [216.7, 122.1, 97.9, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //51000-51999
    [216.7, 122.1, 99.0, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //52000-52999
    [231.0, 134.2, 111.1, 69.3, 46.2, 33.0, 33.0, 33.0, 31.9], //53000-53999
    [231.0, 134.2, 111.1, 69.3, 46.2, 33.0, 33.0, 33.0, 31.9], //54000-54999
    [204.6, 118.8, 96.8, 59.4, 36.3, 26.4, 26.4, 26.4, 23.1], //55000-55999
    [204.6, 118.8, 96.8, 59.4, 36.3, 26.4, 26.4, 26.4, 23.1], //56000-56999
    [216.7, 122.1, 97.9, 60.5, 37.4, 28.6, 28.6, 28.6, 27.5], //57000-57999
    [232.1, 136.4, 112.2, 70.4, 47.3, 36.3, 36.3, 36.3, 33.0], //58000-58999
    [232.1, 136.4, 112.2, 70.4, 47.3, 36.3, 36.3, 36.3, 33.0], //59000-59999
    [235.4, 144.1, 114.4, 73.7, 50.6, 37.4, 37.4, 37.4, 36.3], //60000-60999
    [243.1, 150.7, 121.0, 80.3, 53.9, 39.6, 39.6, 39.6, 38.5], //61000-61999
    [260.7, 161.7, 129.8, 86.9, 60.5, 48.4, 48.4, 47.3, 45.1], //63000-63999
    [251.9, 155.1, 123.2, 82.5, 59.4, 42.9, 42.9, 42.9, 39.6], //64000-64999
    [251.9, 155.1, 123.2, 82.5, 59.4, 42.9, 42.9, 42.9, 39.6], //65000-35999
    [243.1, 150.7, 121.0, 80.3, 53.9, 39.6, 39.6, 39.6, 38.5], //66000-66999
    [260.7, 161.7, 129.8, 86.9, 60.5, 48.4, 48.4, 47.3, 45.1], //67000-67999
    [260.7, 161.7, 129.8, 86.9, 60.5, 48.4, 48.4, 47.3, 45.1], //68000-68999
    [235.4, 144.1, 114.4, 73.7, 50.6, 37.4, 37.4, 37.4, 36.3], //69000-69999
    [243.1, 150.7, 121.0, 80.3, 53.9, 39.6, 39.6, 39.6, 38.5], //70000-70999
    [251.9, 155.1, 123.2, 82.5, 59.4, 42.9, 42.9, 42.9, 39.6], //71000-71999
    [260.7, 161.7, 129.8, 86.9, 60.5, 48.4, 48.4, 47.3, 45.1], //72000-72999
    [266.2, 169.4, 132.0, 93.5, 66.0, 49.5, 49.5, 48.4, 47.3], //73000-73999
    [308.0, 190.3, 150.7, 106.7, 77.0, 50.6, 50.6, 50.6, 48.4], //74000-74999 
    [295.9, 188.1, 146.3, 103.4, 73.7, 49.5, 49.5, 48.4, 47.3], //75000-75999
    [308.0, 190.3, 150.7, 106.7, 77.0, 50.6, 50.6, 50.6, 48.4], //76000-76999
    [295.9, 188.1, 146.3, 103.4, 73.7, 49.5, 49.5, 48.4, 47.3], //77000-77999
    [295.9, 188.1, 146.3, 103.4, 73.7, 49.5, 49.5, 48.4, 47.3], //78000-78999
    [308.0, 190.3, 150.7, 106.7, 77.0, 50.6, 50.6, 50.6, 48.4], //79000-79999
    [312.4, 195.8, 160.6, 113.3, 80.3, 55.0, 55.0, 53.9, 52.8], //80000-80999
    [312.4, 195.8, 160.6, 113.3, 80.3, 55.0, 55.0, 53.9, 52.8], //81000-81999
    [393.8, 253.0, 199.1, 149.6, 106.7, 70.4, 70.4, 70.4, 68.2], //82000-82999 
    [423.5, 281.6, 238.7, 188.1, 133.1, 90.2, 88.0, 90.2, 85.8], //83000-83999
    [411.4, 269.5, 220.7, 167.2, 121.1, 80.3, 80.3, 80.3, 75.9], //84000-84999
    [400.4, 260.7, 215.6, 161.7, 114.4, 77.0, 77.0, 77.0, 71.5], //85000-85999
    [411.4, 269.5, 220.0, 167.2, 121.0, 80.3, 80.3, 80.3, 75.9], //86000-86999
    [411.4, 269.5, 220.0, 167.2, 121.0, 80.3, 80.3, 80.3, 75.9], //87000-87999
    [415.8, 270.6, 221.1, 177.1, 125.4, 81.4, 81.4, 81.4, 81.4], //88000-88999
    [415.8, 270.6, 221.1, 177.1, 125.4, 81.4, 81.4, 81.4, 81.4], //89000-89999
    [423.5, 281.6, 238.7, 188.1, 133.1, 90.2, 90.2, 90.2, 85.8], //90000-90999
    [438.9, 295.9, 253.0, 205.7, 144.1, 95.7, 95.7, 95.7, 93.5], //91000-91999
    [438.9, 295.9, 253.0, 205.7, 144.1, 95.7, 95.7, 95.7, 93.5], //92000-92999
    [459.8, 312.4, 271.7, 223.3, 158.4, 99.0, 99.0, 99.0, 99.0], //93000-93999
    [459.8, 312.4, 271.7, 223.3, 158.4, 99.0, 99.0, 99.0, 99.0], //94000-94999
    [474.1, 327.8, 287.1, 233.2, 168.3, 107.8, 107.8, 107.8, 104.5], //95000-95999
    [485.1, 338.8, 298.1, 243.1, 179.3, 112.2, 112.2, 112.2, 112.2], //96000-96999
    [465.3, 317.9, 281.6, 232.1, 162.8, 104.5, 104.5, 104.5, 102.3], //97000-97999
    [503.8, 356.4, 313.5, 257.4, 199.1, 123.2, 123.2, 123.2, 121.0], //98000-98999
]


export const InputField = props => {
    return (
        <div className = "field">
            <label className = "label">{props.text}</label>
            <div className = "control">
                <input className = {props.type === "checkbox" ? null : "input"}
                       placeholder = {props.placeholder}
                       type = {props.type}
                       value = {props.value}
                       name={props.name}
                       onChange = {props.onChange}
                       onKeyPress = {props.onKeyPress}/>
            </div>
        </div>
    )
};

export const zipArray =
    [1000, 3999, 4999, 7699, 9999];

export const kgArray = [1, 101, 201, 301, 401, 501, 601, 1001, 1501, 3001, 5001, 7001, 10001, 15001, 20001];

export const priceArray = [
    [103,  93,  83,  82,  79,  70,  66,  65,  63,  35,  32,  30,  27,  26,  25], //Zone 1
    [129, 114, 107, 104,  95,  85,  83,  80,  67,  36,  34,  31,  28,  27,  26], //Zone 2
    [135, 120, 112, 109, 100,  89,  87,  84,  70,  38,  36,  33,  29,  28,  27], //Zone 3
    [142, 125, 118, 114, 105,  94,  91,  88,  74,  40,  37,  34,  31,  30,  28]  //Zone 4
];
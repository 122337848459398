import React, {Component} from 'react';
import { FaSignOutAlt } from "react-icons/fa";

import Login from "./components/login/Login"
import CalculatorDerbigum from "./components/derbigum/Calculator";
import CalculatorHydro from "./components/Hydro/Calculator";


class App extends Component {

    state = {
        loggedIn: false
    };

    setLoggedIn = (value) => {
        this.setState({loggedIn: value})
    };

    logout = () => {
        localStorage.clear();
        this.setLoggedIn(false);
        window.location.reload();
    };

    render() {
        if (this.state.loggedIn) {
            return (
                <div style={{padding: 15}}>
                    <div className="container-fluid" height="10">
                        <div className="columns" >
                            <div className="column is-2"/>
                            <div className="column is-8">
                                <img src={localStorage.getItem('logo')} alt="Logo" width="388"/>
                            </div>
                            <div className="column is-1"/>
                            <div className="column is-1">
                                <FaSignOutAlt onClick={() => this.logout()}/>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    <div className="container-fluid" style={{background: "#F5F5F5", height:738}}>
                        <div className="columns">
                            <div className="column is-2"/>
                            <div className="column is-8">
                                <p className={"is-text is-size-3"} style={{fontWeight: 700}}>Calculator for: {localStorage.getItem('client')}</p>
                                <p className={"is-text is-size-5"} >{localStorage.getItem('title')}</p>
                                {
                                    localStorage.getItem('client') === 'Hydro' ?
                                        <CalculatorHydro width="512"/> :
                                    localStorage.getItem('client') === 'Derbigum' ?
                                        <CalculatorDerbigum width="512"/> : null
                                }
                            </div>
                            <div className="column is-2"/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <Login setLoggedIn={this.setLoggedIn}/>
            )
        }
    }
}

export default App;


import React, {Component} from 'react';
import {dev, devIp, ip} from "../../../package.json"
import { InputField } from '../shared/InputField';
import {zipArray, kgArray, priceArray} from './Data';
import Logo_Client from "../../images/logo.png";


class Calculator extends Component {

    state = {
        isVetlanda: true,
        isFinspang: false,
        zipCode: 1,
        weight: 1,
        ldm: 0,
        cbm: 0,
        price: 0,
        oilSurcharge: 0,
        environmentalSurcharge: 0,
        ip: dev ? devIp : ip,
        client: "Hydro",
        title: "Rates from Swedish cities to Denmark",
        logo: Logo_Client
    };

    componentDidMount() {
        //localStorage.setItem("client", this.state.client);
        localStorage.setItem("title", this.state.title);
        localStorage.setItem("logo", this.state.logo);

        fetch(this.state.ip + "settings?client=" + this.state.client)
            .then(res => res.json().then(result => {

                this.setState({ oilSurcharge : result.oilSurcharge}, this.calcPrice);
                this.setState({ environmentalSurcharge : result.environmentalSurcharge}, this.calcPrice);

                //this.setState({ environmentalSurcharge : 44}, this.calcPrice);
            }));
    }

    handleChange = e => {
        e.target.value = e.target.value.replace(" ", "")
        if (e.target.name === "ldm" && e.target.value > 38) {
            // IGNORED
        } else if (e.target.name === "weight" && e.target.value > 25000) {
            // IGNORED
        } else if (e.target.name === "cbm" && e.target.value > 25000) {
            // IGNORED
        } else if (e.target.name === "zipCode" && e.target.value > 9999) {
            // IGNORED
        } else {
            this.setState({[e.target.name]: e.target.value.replace(",", ".")}, this.calcPrice)
        }
    };

    handleIsVetlandaChecked = (e) => {

        this.setState({
            isVetlanda: e.target.checked,
            isFinspang: !e.target.checked
        });

        this.handleChange(e);
    }

    handleIsFinspangChecked = (e) => {
        this.setState({
            isFinspang: e.target.checked,
            isVetlanda: !e.target.checked,
        });

        this.handleChange(e);
    }

    calcPrice() {
        let price = this.lookupPrice();
        let calcPrice = 0;
        let calcPriceNext = 0;

        let isFinspang = this.state.isFinspang ? 0.2 : 0;

        calcPrice = (price.handlingWeight / 100) * price.price * (1 + isFinspang) * (1 + price.extra) + price.extra_1;
        calcPriceNext = (price.handlingWeightNext / 100) * price.priceNext * (1 + price.extra) + price.extra_1;

        //this.setState({price: calcPrice < calcPriceNext ? calcPrice: calcPriceNext})

        this.setState({price: calcPrice});
    }

    lookupPrice = () => {

        // Calc Weight, Handling Weight
        let weight = this.state.weight * 1;
        let ldmWeight = this.state.ldm * 1750;
        let cbmWeight = this.state.cbm * 300;
        let calcWeight = this.state.weight > ldmWeight ? (this.state.weight > cbmWeight ? this.state.weight : cbmWeight) : ldmWeight;

        let handlingWeight = this.handling(calcWeight);
        let handlingWeightNext = handlingWeight;

        // ZipCode Index
        let max = 0;
        for (let i = 0; i < zipArray.length; i++) {
            if (zipArray[i] < this.state.zipCode) {
                max = i;
            }
        }

        // Weight Index
        let kgMax = 0;
        let kgMaxNext = 0;
        for (let i = 0; i < kgArray.length; i++) {
            if (kgArray[i] <= handlingWeight) {
                kgMax = i;
                kgMaxNext = i;
            }
            else {
                kgMaxNext = i;
                handlingWeightNext = kgArray[i];
                break;
            }
        }

        // Oil Surcharge
        let extra = this.state.oilSurcharge / 100;

        // Environmental Surcharge
        let extra_1 = this.state.environmentalSurcharge;

        return {price: priceArray[max][kgMax], priceNext: priceArray[max][kgMaxNext], weight, ldmWeight, calcWeight, handlingWeight, handlingWeightNext, extra, extra_1};
    };

    handling = weight => {
        let handingWeight = Math.ceil((weight / 100)) * 100;
        return handingWeight;
    };

    render() {
        return (
            <div style={{padding: 10, margin: 10}}>
                <div className={"columns"}>
                    <div className={"card column is-4"}>
                        <div className={"row"}>
                            <label style={{fontWeight: 700}}>Vetlanda</label>
                            <input type="checkbox" style={{margin: 6}} checked={this.state.isVetlanda} onChange={this.handleIsVetlandaChecked} name={"Vetlanda"}/>

                            <label>       </label>
                            <label style={{fontWeight: 700}}>Finspång</label>
                            <input type="checkbox" style={{margin: 6}} checked={this.state.isFinspang} onChange={this.handleIsFinspangChecked} name={"Finspang"}/>
                        </div>
                        <hr/>

                        <InputField type="text" text={"Zip Code:"} value={this.state.zipCode} onChange={this.handleChange} name={"zipCode"}/>
                        <InputField type="number" text={"Weights:"} value={this.state.weight} onChange={this.handleChange} name={"weight"}/>
                        <InputField type="number" text={"LDMs:"} value={this.state.ldm} onChange={this.handleChange} name={"ldm"}/>
                        <InputField type="number" text={"CBMs:"} value={this.state.cbm} onChange={this.handleChange} name={"cbm"}/>
                        <h1>{"Freight: " + Math.round(this.state.price * 100) / 100 + " DKK"}</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default Calculator;

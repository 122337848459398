import React, {Component} from 'react';
import {dev, devIp, ip} from "../../../package.json"
import { InputField } from '../shared/InputField';
import {zipArray, kgArray, priceArray} from './Data';
import Logo_Client from "../../images/derbigum.png";

class Calculator extends Component {

    state = {
        zipCode: 1,
        weight: 1,
        pallet: 0,
        price: 0,
        oilSurcharge: 0,
        ip: dev ? devIp : ip,
        client: "Derbigum",
        title:"Rates from Malmo to Swedish cities",
        logo: Logo_Client
    };

    componentDidMount() {
        //localStorage.setItem("client", this.state.client);
        localStorage.setItem("title", this.state.title);
        localStorage.setItem("logo", this.state.logo);

        fetch(this.state.ip + "settings?client=" + this.state.client)
            .then(res => res.json().then(result => {
                this.setState({ oilSurcharge: result.oilSurcharge}, this.calcPrice);
            }));
    }

    handleChange = e => {
        e.target.value = e.target.value.replace(" ", "")
        if (e.target.name === "pallet" && e.target.value > 38) {
            // IGNORED
        } else if (e.target.name === "weight" && e.target.value > 36000) {
            // IGNORED
        } else if (e.target.name === "zipCode" && e.target.value > 98999) {
            // IGNORED
        } else {
            this.setState({[e.target.name]: e.target.value.replace(",", ".")}, this.calcPrice)
        }
    };

    calcPrice() {
        let price = this.lookupPrice();
        let calcPrice = 0;
        let calcPriceNext = 0;

        calcPrice = (price.handlingWeight / 100) * price.price * (1 + price.extra);
        calcPriceNext = (price.handlingWeightNext / 100) * price.priceNext * (1 + price.extra);

        this.setState({price: calcPrice < calcPriceNext ? calcPrice: calcPriceNext})
    }

    lookupPrice = () => {

        // Calc Weight, Handling Weight
        let weight = this.state.weight * 1;
        let palletWeight = this.state.pallet * 0.5 * 1850;
        let calcWeight = this.state.weight > palletWeight ? this.state.weight : palletWeight;
        let handlingWeight = this.handling(calcWeight);
        let handlingWeightNext = handlingWeight;

        // ZipCode Index
        let max = 0;
        for (let i = 0; i < zipArray.length; i++) {
            if (zipArray[i] < this.state.zipCode) {
                max = i;
            }
        }

        // Weight Index
        let kgMax = 0;
        let kgMaxNext = 0;
        for (let i = 0; i < kgArray.length; i++) {
            if (kgArray[i] <= handlingWeight) {
                kgMax = i;
                kgMaxNext = i;
            }
            else {
                kgMaxNext = i;
                handlingWeightNext = kgArray[i];
                break;
            }
        }

        // Oil Surcharge
        let extra = this.state.oilSurcharge / 100;

        return {price: priceArray[max][kgMax], priceNext: priceArray[max][kgMaxNext], weight, palletWeight, calcWeight, handlingWeight, handlingWeightNext, extra};
    };

    handling = weight => {
        let handingWeight = Math.ceil((weight / 100)) * 100;

        if (handingWeight <= 100)
            handingWeight = 200;

        return handingWeight;
    };

    render() {
        return (
            <div style={{padding: 10, margin: 10}}>
                <div className={"columns"}>
                    <div className={"card column is-4"}>
                        <InputField type="text" text={"Zip Code:"} value={this.state.zipCode} onChange={this.handleChange} name={"zipCode"}/>
                        <InputField type="number" text={"No of pallets:"} value={this.state.pallet} onChange={this.handleChange} name={"pallet"}/>
                        <InputField type="number" text={"Weight:"} value={this.state.weight} onChange={this.handleChange} name={"weight"}/>
                        <h1>{"Freight: " + Math.round(this.state.price * 100) / 100 + " SEK"}</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default Calculator;
